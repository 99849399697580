<template>
  <bm-overlay
    ref="customOverlay"
    :class="['sample', iconType]"
    pane="labelPane"
    @draw="draw"
  >
  </bm-overlay>
</template>

<script>
import BmOverlay from 'vue-baidu-map/components/overlays/Overlay'

export default {
  name: 'DashboardOverlay',
  props: ['markerData'],
  components: {
    BmOverlay
  },
  watch: {
    markerData: {
      handler() {
        this.$refs.customOverlay.reload()
      },
      deep: true
    }
  },
  computed: {
    iconType() {
      switch (this.markerData.Status) {
        case 'NORMAL':
          return 'mapOnline'
        case 'EXCESS':
          return 'mapAlert'
        case 'DOWN':
          return 'mapOffline'
        case 'OFF':
          return 'mapClosed'
        case 'ALARM':
          return 'mapPreAlert'
        default:
          return '@locale'
      }
    }
  },
  methods: {
    draw({ el, BMap, map }) {
      const { Lng, Lat } = this.markerData
      const pixel = map.pointToOverlayPixel(new BMap.Point(Lng, Lat))
      el.style.left = pixel.x - 14 + 'px'
      el.style.top = pixel.y - 20.5 + 'px'

    },
    getIcon(status) {
      switch (status) {
        case 'NORMAL':
          return '@/asset/img/mapOnline.png'
        case 'EXCESS':
          return '@/asset/img/mapAlert.png'
        case 'DOWN':
          return '@/asset/img/mapOffline.png'
        case 'OFF':
          return '@/asset/img/mapClosed.png'
        case 'ALARM':
          return '@/asset/img/mapPreAlert.png'
        default:
          return '@/asset/img/locale.png'
      }
    }
  }
}
</script>

<style scoped>
.sample {
  width: 28px;
  height: 41px;
  position: absolute;
}
.sample:hover {
  cursor: pointer;
}
.mapOnline {
  background: url('~@/asset/img/mapOnline.png') no-repeat;
}
.mapAlert {
  background: url('~@/asset/img/mapAlert.png') no-repeat;
}
.mapOffline {
  background: url('~@/asset/img/mapOffline.png') no-repeat;
}
.mapClosed {
  background: url('~@/asset/img/mapClosed.png') no-repeat;
}
.mapPreAlert {
  background: url('~@/asset/img/mapPreAlert.png') no-repeat;
}
.locale {
  background: url('~@/asset/img/locale.png') no-repeat;
}
</style>
